<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Hub
       </h4>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-book-reader"></i
                ></span>
                <h1>School Profile</h1>
              </div>
              <!-- <button type="button" class="backbtn" @click="previousRoute">
                <i class="fa fa-arrow-left"></i>
              </button> -->
            </div>
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input
                  type="radio"
                  name="tabs"
                  id="academictab"
                  checked="checked"
                />
                <label for="academictab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" >School Information</label>
                <div class="tab">
                  
                  <div style="overflow-y:auto">
                    
                    <form
                      data-vv-scope="schOverview"
                      id="schOverview"
                      accept-charset="utf-8"
                    > 
                    <!-- style="right: 1px;
    top: 67px;
    position: absolute;" -->
                    <div class="widjetfooter text-right" >
                    <div class="dk_iconsml">
                       <button
                        v-if="!isEdit"
                        type="button"
                        class="btn btnsml edbtn"
                        @click.prevent="editDetails"
                      >
                        <i class="fas fa-edit"></i> <span>Edit</span>
                      </button>
                     
                    </div>
                  </div>
                    

                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-1"
                          class="blutitle accordion-label"
                        >
                          Basic Details
                        </label>
                        <div class="form-row">
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="schname"
                                v-validate="'required'"
                                v-model="schoolDetails.accountName"
                                class="form-control form-input"
                                id="schnameid"
                                :disabled="!isEdit"
                                placeholder="School Name"
                              />
                              <label
                                v-if="errors.first('schOverview.schname')"
                                class="vi-error"
                                style="top:-1em"
                                >School Name is required</label
                              >
                              <label for="schnameid" v-else
                                >School Name
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                          <div class="form-group input-group col-md-6 mt-3">
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="afname"
                                @focusout="checkAffilliateId"
                                v-validate="'required'"
                                :disabled="!isEdit"
                                v-model="schoolDetails.affilliateId"
                                class="form-control form-input"
                                id="afid"
                                placeholder="Affiliate ID"
                              />
                              <label
                                v-if="
                                  errors.first('schOverview.afname') ||
                                    !isAffiliateIdAvailable
                                "
                                class="vi-error"
                                style="top: -1em"
                                >{{
                                  errors.first("schOverview.afname")
                                    ? "Affiliate Id is required"
                                    : "Affiliate Id already registered. please use a different one."
                                }}</label
                              >

                              <label for="afid" v-else
                                >Affiliate ID
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <select
                                class="form-control form-input"
                                id="schtypeid"
                                name="schtypeid"
                                :disabled="!isEdit"
                                v-validate="'required'"
                                v-model="schoolDetails.schoolType"
                                placeholder="School Type"
                              >
                                <option :value="null">-- Select --</option>
                                <option
                                  v-for="(item, index) in schoolTypeList"
                                  :key="index"
                                  :value="item"
                                >
                                  {{ item }}
                                </option>
                              </select>
                              <label
                                v-if="errors.first('schOverview.schtypeid')"
                                class="vi-error"
                                style="top:-1em"
                                >School Type is required</label
                              >
                              <label for="schtypeid" v-else
                                >School Type
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <v-select
                                class="form-control form-input"
                                label="label"
                                multiple
                                name="AffiliatedtoBoard"
                                v-validate="'required'"
                                :disabled="!isEdit"
                                v-model="schoolDetails.affilliateIdToBoard"
                                :options="boardTypeList"
                                :reduce="(label) => label.value"
                              ></v-select>

                              <label
                                for="gardeTo"
                                class="vi-error"
                                style="top:-1em"
                                v-if="
                                  errors.first('schOverview.AffiliatedtoBoard')
                                "
                                >Affiliated to/Board is required
                                <span class="required">*</span></label
                              >
                              <label for="gardeTo" v-else
                                >Affiliated to/Board
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <select
                                :disabled="!isEdit"
                                @change="
                                  setToClassAndSection(schoolDetails.gradeFrom)
                                "
                                v-model="schoolDetails.gradeFrom"
                                class="form-control form-input"
                                id="acyearid"
                                v-validate="'required'"
                                name="gradefrom"
                              >
                                <option :value="null">-- Select --</option>
                                <option
                                  v-for="(item, index) in fromClassList"
                                  :key="index"
                                  :value="item"
                                >
                                  {{ item }}
                                </option>
                              </select>
                              <label
                                v-if="errors.first('schOverview.gradefrom')"
                                class="vi-error"
                                style="top:-1em"
                                >Grade From is required</label
                              >
                              <label for="schtypeid" v-else>Grade From </label>
                            </span>
                          </div>
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <select
                                :disabled="!isEdit"
                                v-model="schoolDetails.gradeTo"
                                class="form-control form-input"
                                id="gardeTo"
                                v-validate="'required'"
                                name="gardeTo"
                              >
                                <option :value="null">-- Select --</option>
                                <option
                                  v-for="(item, index) in toClassList"
                                  :key="index"
                                  :value="item"
                                >
                                  {{ item }}
                                </option>
                              </select>

                              <label
                                v-if="errors.first('schOverview.gardeTo')"
                                class="vi-error"
                                style="top:-1em"
                                >Grade To is required</label
                              >
                              <label for="gardeTo" v-else
                                >Grade To <span class="required">*</span></label
                              >
                            </span>
                          </div>

                          <!-- <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                             <v-select class="form-control form-input"
                              label="label"
                              name="loi"
                              v-validate="'required'"
                              multiple
                              :options="schoolTypeList"
                              :reduce="label => label.value"
                            ></v-select>
                             
                              <label for="gardeTo"  v-if="errors.first('schOverview.loi')"
                                >Language of instruction is required
                                <span class="required">*</span></label
                              >
                              <label for="gardeTo"  v-else
                                >Language of instruction
                                <span class="required">*</span></label
                              >
                            </span>
                          </div> -->
                          <div
                            class="form-group input-group col-md-6 mt-3"
                            style="border-bottom: 1px solid #e5e5e5"
                          >
                            <span style="color: #6c757d" class="mr-3">
                              School Affiliation status is Active ?
                              <span class="required">*</span></span
                            >
                            <label class="switch">
                              <input
                                v-model="
                                  schoolDetails.statusOfSchoolAffiliation
                                "
                                type="checkbox"
                                id="togBtn"
                                :disabled="!isEdit"
                              />
                              <div class="slider"></div>
                            </label>
                          </div>
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="date"
                                max="9999-12-31"
                                v-validate="'required'"
                                name="ysddde"
                                v-model="
                                  schoolDetails.YearOfSchoolEstablishment
                                "
                                class="form-control form-input"
                                :disabled="!isEdit"
                              />
                              <label
                                for="landlineid"
                                class="vi-error"
                                style="top:-1em"
                                v-if="errors.first('schOverview.ysddde')"
                                >Year of School Establishment is required.
                              </label>
                              <label for="landlineid" v-else
                                >Year of School Establishment.
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>

                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                v-model="schoolDetails.schoolAffiliationGranted"
                                type="date"
                                max="9999-12-31"
                                v-validate="schoolDetails.statusOfSchoolAffiliation ? 'required' : false"
                                name="SchoolAffiliationGranted"
                                class="form-control form-input"
                                id="landlineid"
                                :disabled="!isEdit"
                              />
                              <label
                                for="landlineid"
                                class="vi-error"
                                style="top:-1em"
                                v-if="
                                  errors.first(
                                    'schOverview.SchoolAffiliationGranted'
                                  )
                                "
                                >School Affiliation Granted on is required.
                                <span v-if="schoolDetails.statusOfSchoolAffiliation" class="required">*</span></label
                              >
                              <label for="landlineid" v-else
                                >School Affiliation Granted on
                                <span v-if="schoolDetails.statusOfSchoolAffiliation" class="required">*</span></label
                              >
                            </span>
                          </div>

                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="landline"
                                v-model="schoolDetails.registeredName"
                                class="form-control form-input"
                                id="landlineid"
                                :disabled="!isEdit"
                              />
                              <label for="landlineid"
                                >Name of the Trust/Society/institution/company
                                the school Registered with
                              </label>
                            </span>
                          </div>
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="number"
                                name="landline"
                                v-model="schoolDetails.minimumAge"
                                class="form-control form-input"
                                id="landlineid"
                                :disabled="!isEdit"
                              />
                              <label for="landlineid"
                                >Minimum age of entry
                              </label>
                            </span>
                          </div>
                          <!-- <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="landline"
                                class="form-control form-input"
                                id="landlineid"
                                :disabled="!isEdit"
                              />
                              <label for="landlineid"
                                >Total Number of Students 
                                </label
                              >
                            </span>
                          </div> -->

                          <div class="form-group input-group col-md-6 mt-3">
                            <span class="has-float-label">
                              <select
                                class="form-control form-input"
                                id="brdtypeid"
                                name="brdtypeid"
                                :disabled="!isEdit"
                                v-validate="'required'"
                                v-model="schoolDetails.classification"
                              >
                                <option :value="null">-- Select --</option>
                                <option
                                  v-for="(item, index) in classificationList"
                                  :key="index"
                                  :value="item"
                                >
                                  {{ item }}
                                </option>
                              </select>

                              <label
                                v-if="errors.first('schOverview.brdtypeid')"
                                class="vi-error"
                                style="top:-1em"
                                >School Classification is required</label
                              >
                              <label for="brdtypeid" v-else
                                >School Classification
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <v-select
                                label="label"
                                multiple
                                :options="mediumList"
                                :reduce="(label) => label.value"
                                name="Medium"
                                v-validate="'required'"
                                v-model="schoolDetails.medium"
                                class="form-control form-input vselectFrmCtrl"
                                id="mediumid"
                                :disabled="!isEdit"
                              ></v-select>

                              <!-- <select
                                name="Medium"
                                v-validate="'required'"
                                v-model="schoolDetails.medium"
                                class="form-control form-input"
                                id="mediumid"
                                :disabled="!isEdit"
                                placeholder="Medium"
                              >
                                <option :value="null">-- Select --</option>
                                <option
                                  v-for="(item, index) in mediumList"
                                  :key="index"
                                  :value="item"
                                >
                                  {{ item }}
                                </option>
                              </select> -->
                              <label
                                v-if="errors.first('schOverview.Medium')"
                                class="vi-error"
                                style="top:-1em"
                                >Language of instruction is required</label
                              >
                              <label for="mediumid" v-else
                                >Language of instruction
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                          <!-- <div
                            class="form-group input-group col-md-6 mt-3"
                            style="border-bottom: 1px solid #e5e5e5"
                          >
                            <span style="color: #6c757d" class="mr-3">
                              Admission open ?
                              <span class="required">*</span></span
                            >
                            <label class="switch">
                              <input
                                type="checkbox"
                                id="togBtn"
                                v-model="schoolDetails.isAdmissionAvailable"
                                disabled
                              />
                              <div class="slider"></div>
                            </label>
                          </div> -->
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="landline"
                                class="form-control form-input"
                                id="landlineid"
                                :disabled="!isEdit"
                                v-model="schoolDetails.landlineNo"
                                placeholder="Landline No."
                              />
                              <label for="landlineid"
                                >Landline No
                                </label
                              >
                            </span>
                          </div>
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="website"
                                class="form-control form-input"
                                id="websiteid"
                                :disabled="!isEdit"
                                v-validate="'url'"
                                v-model="schoolDetails.website"
                                placeholder="Enter your school website"
                              />
                              <label
                                v-if="errors.first('schOverview.website')"
                                class="vi-error"
                                style="top:-1em"
                                >Website url format is wrong ( eg:
                                https://app.vidhyaan.com )</label
                              >
                              <label for="mediumid" v-else>Website </label>
                            </span>
                          </div>
                           <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <select
											class="form-control form-input"
											id="deptlistid"
                      name="currencyId"
                      :disabled="!isEdit"
                      v-validate="'required'"
											v-model="schoolDetails.currencyCode"
											>
											<option :value=null>--Select--</option>
											<option v-for="(item,index) in currencyList" :key="index" :value="item.value">
												{{ item.label }}
											</option>
											</select>
                              <label
                                v-if="errors.first('schOverview.currencyId')"
                                class="vi-error"
                                style="top:-1em"
                                >Currency is required</label
                              >
                              <label for="mediumid" v-else>Currency </label>
                            </span>
                          </div>

                          <div class="form-group input-group col-md-6 mt-md-3">
                            <span class="has-float-label">
                              <textarea
                                class="form-control form-input"
                                style="min-height: 90px; resize: none"
                                id="abtschlid"
                                :disabled="!isEdit"
                                v-model="schoolDetails.overView"
                                placeholder="Describe about your School"
                              ></textarea>
                              <label for="schadr"
                                >Describe about your School</label
                              >
                            </span>
                          </div>

                          <div
                            class="form-group input-group col-md-6 mt-3"
                            style="border-bottom: 1px solid #e5e5e5"
                          >
                              Types of Admission ?
                              <span class="required">*</span>

                               <div class="nottble" style="margin-top:8px;">
                                <div class=" chkbox" data-title="Select">
                                    <label class="custcheckbox">
                                      <input :disabled="!isEdit" type="checkbox" v-model="schoolDetails.isOnlineAdmission" />
                                      <span class="checkmarkchk"></span>
                                      
                                    </label>
                                    <label style="margin-left:35px;padding-top: 1%;">Online</label>
                                  </div>
                                  
                                </div>
                                 <div class="nottble" style="margin-top:8px;">
                                <div class=" chkbox" data-title="Select">
                                    <label class="custcheckbox">
                                      <input :disabled="!isEdit" type="checkbox" v-model="schoolDetails.isOfflineAdmission" />
                                      <span class="checkmarkchk"></span>
                                      
                                    </label>
                                    <label style="margin-left:35px;padding-top: 1%;">Offline</label>
                                  </div>
                                  
                                </div>
                            
                          </div>

                          <div class="form-group input-group col-md-12 mt-md-3">
                            
                            <span class="has-float-label">
                              <v-select
                                class="form-control form-input vselectFrmCtrl"
                                style="margin-top: 1%;"
                                label="label"
                                name="AssignClasses"
                                v-validate="'required'"
                                multiple
                                v-model="schoolDetails.facilities"
                                :disabled="!isEdit"
                                :options="getformatClassList"
                                :reduce="(label) => label.value"
                              ></v-select>

                              <label
                                v-if="errors.first('schOverview.AssignClasses')"
                                class="vi-error"
                                style="top:-1em"
                                >School Facitilities is required</label
                              >
                              <label v-else
                                >Add your School Facilities :
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-1"
                          class="blutitle accordion-label"
                        >
                          For Vidhyaan Use Only (Read only)
                        </label>

                        <div class="form-row">
                          <div
                            class="form-group input-group col-md-4 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="promoted"
                                class="form-control form-input"
                                id="promotedid"
                                placeholder="Promoted"
                                disabled
                                v-model="schoolDetails.promoted"
                              />
                              <label for="promotedid"
                                >Promoted <span class="required">*</span></label
                              >
                            </span>
                          </div>
                          <div
                            class="form-group input-group col-md-4 mt-3"
                            style="border-bottom: 1px solid #e5e5e5"
                          >
                            <span style="color: #6c757d" class="mr-3"
                              >Is Verified?
                              <span class="required">*</span></span
                            >
                            <label class="switch">
                              <input type="checkbox" v-model="schoolDetails.isVerified" disabled id="togBtn" />
                              <div class="slider"></div>
                            </label>
                          </div>
                           <div
                            class="form-group input-group col-md-4 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="promoted"
                                class="form-control form-input"
                                id="promotedid"
                                placeholder="status"
                                disabled
                                v-model="schoolDetails.status"
                              />
                              <label for="promotedid"
                                >Status <span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-1"
                          class="blutitle accordion-label"
                        >
                          Principal Information
                        </label>
                        <div
                          class="form-row"
                          v-if="schoolDetails.principalInformation"
                        >
                          <div class="form-group input-group col-md-4 mt-3">
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="areaId"
                                :disabled="!isEdit"
                                v-model="
                                  schoolDetails.principalInformation
                                    .PrincipalName
                                "
                                class="form-control form-input"
                                id="ctid"
                              />

                              <label for="ctid">Name </label>
                            </span>
                          </div>
                          <div class="form-group input-group col-md-4 mt-3">
                            <span class="has-float-label">
                              <input
                                type="text"
                                v-validate="'email'"
                                name="prinEMail"
                                :disabled="!isEdit"
                                v-model="
                                  schoolDetails.principalInformation
                                    .PrincipalEmail
                                "
                                class="form-control form-input"
                                id="ctid"
                              />
                              <label
                                v-if="errors.first('schOverview.prinEMail')"
                                class="vi-error"
                                style="top:-1em"
                                >Enter valid email id</label
                              >

                              <label v-else for="ctid">Email </label>
                            </span>
                          </div>
                          <div class="form-group input-group col-md-4 mt-3">
                            <span class="has-float-label">
                              <input
                                onKeyPress="if(this.value.length==15) return false;"
                                type="number"
                                name="areaId"
                                :disabled="!isEdit"
                                v-model="
                                  schoolDetails.principalInformation
                                    .PrincipalPhone
                                "
                                class="form-control form-input"
                                id="ctid"
                              />

                              <label for="ctid">Phone </label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-1"
                          class="blutitle accordion-label"
                        >
                          Social Media Links
                        </label>
                        <div class="form-row" v-if="schoolDetails.socialMedia">
                          <div class="form-group input-group col-md-6 mt-3">
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="areaId"
                                :disabled="!isEdit"
                                v-model="schoolDetails.socialMedia.Facebook"
                                class="form-control form-input"
                                id="ctid"
                              />

                              <label for="ctid">Facebook URL </label>
                            </span>
                          </div>
                          <div class="form-group input-group col-md-6 mt-3">
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="areaId"
                                :disabled="!isEdit"
                                v-model="schoolDetails.socialMedia.Twitter"
                                class="form-control form-input"
                                id="ctid"
                              />

                              <label for="ctid">Twitter URL </label>
                            </span>
                          </div>
                          <div class="form-group input-group col-md-6 mt-3">
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="areaId"
                                :disabled="!isEdit"
                                v-model="schoolDetails.socialMedia.Linkedin"
                                class="form-control form-input"
                                id="ctid"
                              />

                              <label for="ctid">Linkedin URL </label>
                            </span>
                          </div>
                          <div class="form-group input-group col-md-6 mt-3">
                            <span class="has-float-label">
                              <input
                                type="text"
                                name="areaId"
                                :disabled="!isEdit"
                                v-model="schoolDetails.socialMedia.Youtube"
                                class="form-control form-input"
                                id="ctid"
                              />

                              <label for="ctid">Youtube URL </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="widjetfooter text-right">
                    <div class="dk_iconsml">
                       <!-- <button
                        v-if="!isEdit"
                        type="button"
                        class="btn btnsml edbtn"
                        @click.prevent="editDetails"
                      >
                        <i class="fas fa-edit"></i> <span>Edit</span>
                      </button> -->
                      <div v-if="isEdit">
                        <button
                          type="button"
                          class="btn btnsml"
                          id="svbtn"
                          @click.prevent="updateDetails"
                        >
                            <span>Save</span>
                        </button>
                        <button
                          type="button"
                          class="btn btncl"
                          id="clbtn"
                          @click.prevent="editCancel"
                        >
                            
                          <span>Cancel</span>
                        </button>
                      </div>
                    </div>
                    <!--<button type="button" class="btn btncl ">Cancel</button>-->
                  </div>
                </div>
                <input type="radio" name="tabs" id="addresstab" />
                <label for="addresstab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" >School Address</label>
                <div class="tab">
                  <div style="overflow-y:auto">
                    <form
                      data-vv-scope="schOverview"
                      id="schOverview"
                      accept-charset="utf-8"
                    >
                      <div class="form-row">
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="web-has-float-label">
                            <vue-google-autocomplete
                              ref="address"
                              id="map"
                              class="form-control form-input"
                              placeholder="Search Address"
                             :country="['IN', 'CA']"
                              types=""
                              v-on:placechanged="getAddressData"
                            >
                            </vue-google-autocomplete>

                            <!-- <vue-google-autocomplete
                              ref="address"
                              id="map"
                              class="form-control form-input"
                              placeholder="Search Address"
                              country="IN"
                              types=""
                              v-on:placechanged="getAddressData"
                            >
                            </vue-google-autocomplete> -->
                            <label for="countryid" style="font-size: 15px;
    padding-left: 1px;
    padding-top: 0px;
"
                              >Address [powered by Google] <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="has-float-label">
                            <input
                              class="form-control form-input selectpicker"
                              id="stateid"
                              name="state"
                              v-validate="'required|alpha_spaces|max:100'"
                              :disabled="!isEdit"
                              v-model="schoolDetails.state"
                              placeholder="State"
                            />
                            <label
                              v-if="errors.first('schOverview.state')"
                              class="vi-error"
                              style="top:-1em"
                              >State is required</label
                            >
                            <label for="statid"
                              >State <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="has-float-label">
                            <input
                              v-model="schoolDetails.district"
                              :disabled="!isEdit"
                              v-validate="'required|alpha_spaces|max:100'"
                              class="form-control form-input"
                              id="stateid"
                              name="districtId"
                              placeholder="District"
                            />

                            <label
                              v-if="errors.first('schOverview.districtId')"
                              class="vi-error"
                              style="top:-1em"
                              >District is required</label
                            >
                            <label for="disid"
                              >District <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="has-float-label">
                            <input
                              v-model="schoolDetails.pincode"
                              :disabled="!isEdit"
                              v-validate="'required'"
                              class="form-control form-input"
                              id="pincodeid"
                              name="Pincode"
                            />

                            <label
                              v-if="errors.first('schOverview.Pincode')"
                              class="vi-error"
                              style="top:-1em"
                              >Pincode is required</label
                            >

                            <label for="pincdid"
                              >Pincode <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="has-float-label">
                            <input
                              type="text"
                              name="schadr"
                              v-validate="'required'"
                              :disabled="!isEdit"
                              v-model="schoolDetails.address"
                              class="form-control form-input"
                              id="schadrid"
                              placeholder="School Address"
                            />
                            <label
                              v-if="errors.first('schOverview.schadr')"
                              class="vi-error"
                              style="top:-1em"
                              >Street is required</label
                            >
                            <label for="schadrid" v-else
                              >Street <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="has-float-label">
                            <input
                              type="text"
                              name="city"
                              :disabled="!isEdit"
                              v-validate="'required|alpha_spaces|max:100'"
                              v-model="schoolDetails.city"
                              class="form-control form-input"
                              id="ctid"
                              placeholder="City / Town"
                            />
                            <label
                              v-if="errors.first('schOverview.city')"
                              class="vi-error"
                              style="top:-1em"
                              >City / Town is required</label
                            >
                            <label for="ctid" v-else
                              >City / Town
                              <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="has-float-label">
                            <input
                              type="text"
                              name="areaId"
                              :disabled="!isEdit"
                              v-model="schoolDetails.area"
                              class="form-control form-input"
                              id="ctid"
                              placeholder="Area"
                            />

                            <label for="ctid">Area Name</label>
                          </span>
                        </div>
                      </div>

                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-1"
                          class="blutitle accordion-label"
                        >
                          Locate your school on map
                        </label>
                        <div class="form-row">
                          <div class="form-group input-group col-md-12 mt-3">
                            <div id="app" style="width: 100%;">
                              <GmapMap
                                :center="center"
                                :zoom="18"
                                map-style-id="roadmap"
                                :options="mapOptions"
                                style="width: 100%; height: 300px"
                                ref="mapRef"
                              >
                                <GmapMarker
                                  ref="myMarker"
                                  :position="marker.position"
                                  :clickable="true"
                                  :draggable="true"
                                  @drag="handleMarkerDrag"
                                  @click="center = marker.position"
                                />
                              </GmapMap>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="widjetfooter text-right">
                    <div class="dk_iconsml">
                      <button
                        v-if="!isEdit"
                        type="button"
                        class="btn btnsml edbtn"
                        @click.prevent="editDetails"
                      >
                        <i class="fas fa-edit"></i> <span>Edit</span>
                      </button>
                      <div v-if="isEdit">
                        <button
                          type="button"
                          class="btn btnsml"
                          id="svbtn"
                          @click.prevent="updateDetails"
                        >
                            <span>Save</span>
                        </button>
                        <button
                          type="button"
                          class="btn btncl"
                          id="clbtn"
                          @click.prevent="editCancel"
                        >
                            
                          <span>Cancel</span>
                        </button>
                      </div>
                    </div>
                    <!--<button type="button" class="btn btncl ">Cancel</button>-->
                  </div>
                </div>
                <input type="radio" name="tabs" id="logotab" />
                <label for="logotab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" >School Logo</label>
                <div class="tab">
                  <div class="form-row">
                        <div
                          class="form-group input-group col-md-9 pr-md-3 mt-3"
                        >
                          <div
                            class="has-float-label "
                            v-if="
                              schoolDetails.image && schoolDetails.image.path
                            "
                          >
                            <div class="schlogo">
                              <img
                                v-if="
                                  schoolDetails.image &&
                                    schoolDetails.image.path
                                "
                                :src="schoolDetails.image.path"
                                alt="Avatar"
                                class="mt-3"
                                width="110"
                                height="110"
                              />
                              <label for="ptmbnid"
                                >School Logo<span class="required"
                                  >*</span
                                ></label
                              >
                              <div class="upldIcon">
                                <i class="fas fa-school"></i>
                                <input
                                  type="file"
                                  ref="schoolImagechangeFile"
                                  name="schoolImagechangeFile"
                                  accept="image/*"
                                  @change="
                                    updateFile(
                                      'schoolImage',
                                      'schoolImagechangeFile'
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div class="" v-else>
                            <div class="schdp">
                              <i class="fas fa-school"></i>
                              <input
                                class="schdpupload"
                                type="file"
                                ref="schoolImageFile"
                                name="schoolImageFile"
                                accept="image/*"
                                @change="
                                  updateFile('schoolImage', 'schoolImageFile')
                                "
                              />
                            </div>
                            <div>
                              Upload School Logo
                            </div>
                          </div>
                        </div>
                       
                       </div>
                </div>
                <input type="radio" name="tabs" id="gallerytab" />
                <label for="gallerytab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" >Gallery</label>
                <div class="tab">
                  <div class="row">
                  <div class="col-md-2">
                  <div class="schdp" style="margin:0">
                    <i class="far fa-images" style="padding-top:6px;"></i>
                    <input
                      class="galupload"
                      :disabled="galleryList.length > 9"
                      type="file" 
                      id="importExportID"
                      ref="schoolImageGalleryFile"
                      name="schoolImageGalleryFile"
                      accept="image/*"
                      @change="
                        updateGalleryFile('gallery', 'schoolImageGalleryFile')
                      "
                    />
                  </div>
                 

                  <div class="ml-2">Upload Photo</div>
                  </div>
                  <div class="col-md-10 pt-5">
                     <span class="alert alert-info" style="padding:0.75rem"><i class="fa fa-info-circle" aria-hidden="true"></i> | You can upload upto 10 images and one after the other</span>
                  </div>
                </div>
                  <div>
                    
                    <div class="row">
                      <div
                        class="col-md-2"
                        v-for="(item, index) in galleryList"
                        :key="index"
                      >
                        <div class="galcontainer">
                          <img
                            :src="item.path"
                            @click.prevent="
                              openGalleryShowPopUp(index, item.path)
                            "
                            alt="Avatar"
                            class="mt-3 galimage"
                          />
                          <div class="galoverlay">
                            <span
                              @click.prevent="removepic(index)"
                              id="clbtn"
                              style="border-top-left-radius: 0px;border-radius: 0rem;"
                            >
                              <i class="fas fa-trash"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <vi-spinner
        v-if="viLoader"
        text="Loading..."
        textColor="vi-brand-color"
        class="flex-fill h-100 vi-fs12"
        style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
      /> -->
    </div>
    <div>
      <b-modal id="imagePopup" no-close-on-backdrop no-close-on-esc>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Change your school picture</h2>
          <br />
          <div class="row">
            <div class="col-lg-6">
              <img
                :src="schoolDetails.image.path"
                alt="Avatar"
                class="mt-3"
                width="150"
                height="150"
              />
            </div>
            <div class="col-lg-2">
              <input
                type="file"
                ref="schoolImagechangeFile"
                name="schoolImagechangeFile"
                accept="image/*"
                @change="updateFile('schoolImage', 'schoolImagechangeFile')"
              />
              Upload Photo
            </div>
            <div class="col-lg-2">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="removeUploadFile('schoolImage')"
              >
                  <span>Remove </span>
              </button>
            </div>
            <div class="col-lg-2">
              <button
                type="button"
                @click.prevent="closeImagePopUp"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- </div> -->
      </b-modal>
    </div>
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
    />

  </div>
</template>
<script>
import ViService from "@/services/ViService";
import ViSpinner from "../Common/ViSpinner";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "Class",
  components: {
    "v-select": Select,
    ViSpinner,
    VueGoogleAutocomplete,
  },

  data() {
    return {
      viLoader: false,
      isAffiliateIdAvailable: true,
      schoolDetails: {},
      Facilities: [],
      currencyList: [
       {
        label: 'AED - UAE Dirham',
        value: 'AED'
       },
       {
        label: 'CAD - canadian dollar',
        value: 'CAD'
       },
       {
        label: 'EUR - Euro',
        value: 'EUR'
       },
       {
        label: 'INR - Indian Rupee',
        value: 'INR'
       },
       {
        label: 'USD - United States Dollar',
        value: 'USD'
       }
      ],
      fromClassList: [
        "Pre-Nursery",
        "Nursery",
        "LKG",
        "UKG",
        "KG",
        "Class 1",
        "Class 2",
        "Class 3",
        "Class 4",
        "Class 5",
        "Class 6",
        "Class 7",
        "Class 8",
        "Class 9",
        "Class 10",
        "Class 11",
        "Class 12",
      ],
      toClassList: [],
      isEdit: false,
      galleryList: [],
      stateList: {
        AN: "Andaman and Nicobar Islands",
        AP: "Andhra Pradesh",
        AR: "Arunachal Pradesh",
        AS: "Assam",
        BR: "Bihar",
        CH: "Chandigarh",
        CT: "Chhattisgarh",
        DN: "Dadra and Nagar Haveli",
        DD: "Daman and Diu",
        DL: "Delhi",
        GA: "Goa",
        GJ: "Gujarat",
        HR: "Haryana",
        HP: "Himachal Pradesh",
        JK: "Jammu and Kashmir",
        JH: "Jharkhand",
        KA: "Karnataka",
        KL: "Kerala",
        LD: "Lakshadweep",
        MP: "Madhya Pradesh",
        MH: "Maharashtra",
        MN: "Manipur",
        ML: "Meghalaya",
        MZ: "Mizoram",
        NL: "Nagaland",
        OR: "Odisha",
        PY: "Puducherry",
        PB: "Punjab",
        RJ: "Rajasthan",
        SK: "Sikkim",
        TN: "Tamil Nadu",
        TG: "Telangana",
        TR: "Tripura",
        UP: "Uttar Pradesh",
        UT: "Uttarakhand",
        WB: "West Bengal",
      },
      schoolTypeList: [
        "Private",
        "Government",
        "Public Aided",
        "International",
        "National open school",
        "Special-needs",
        "Home school",
        "Distance education",
        "Montessori",
      ],
      boardTypeList: [
        {
          label: "State Board",
          value: "State Board",
        },
        {
          label: "STATE BOARD (till 10th)",
          value: "STATE BOARD (till 10th)",
        },
        {
          label: "STATE BOARD (till 12th)",
          value: "STATE BOARD (till 12th)",
        },
        {
          label: "CBSE",
          value: "CBSE",
        },
        {
          label: "ICSE",
          value: "ICSE",
        },
        {
          label: "ICSE & ISC",
          value: "ICSE & ISC",
        },
        {
          label: "IGCSE",
          value: "IGCSE",
        },
        {
          label: "IGCSE & CIE",
          value: "IGCSE & CIE",
        },
        {
          label: "CIE",
          value: "CIE",
        },
        {
          label: "1B",
          value: "1B",
        },
        {
          label: "IB PYP ONLY",
          value: "IB PYP ONLY",
        },
        {
          label: "IB PYP & MYP ONLY",
          value: "IB PYP & MYP ONLY",
        },
        {
          label: "IB PYP MYP & DYP",
          value: "IB PYP MYP & DYP",
        },
        {
          label: "IB DP",
          value: "IB DP",
        },
        {
          label: "To be affiliated to CBSE",
          value: "To be affiliated to CBSE",
        },
        {
          label: "To be affiliated to ISC/ICSE",
          value: "To be affiliated to ISC/ICSE",
        },
        {
          label: "To be affiliated to STATE/OTHER BOARD",
          value: "To be affiliated to STATE/OTHER BOARD",
        },
        {
          label: "To be affiliated to INTERNATIONAL BOARD",
          value: "To be affiliated to INTERNATIONAL BOARD",
        },
      ],
      classificationList: [
        "Co-Ed School",
        "Only Boys School",
        "Only Girls School",
      ],
      mediumList: [
        {
          label: "English",
          value: "English",
        },
        {
          label: "Tamil",
          value: "Tamil",
        },
        {
          label: "Telugu",
          value: "Telugu",
        },
        {
          label: "Urdu",
          value: "Urdu",
        },
        {
          label: "Sanskrit",
          value: "Sanskrit",
        },
        {
          label: "Kannada",
          value: "Kannada",
        },
        {
          label: "Malayalam",
          value: "Malayalam",
        },
        {
          label: "Odia",
          value: "Odia",
        },
        {
          label: "Punjabi",
          value: "Punjabi",
        },
        {
          label: "Hindi",
          value: "Hindi",
        },
        {
          label: "Bengali",
          value: "Bengali",
        },
        {
          label: "Gujarati",
          value: "Gujarati",
        },
        {
          label: "Assamese",
          value: "Assamese",
        },
      ],
      address: "",
      ddd:
        "https://vidhyaan.sgp1.digitaloceanspaces.com/Platform-App-Builder-678x381.png",
      marker: { position: { lat: 13.061931068989976, lng: 80.25831401348114 } },
      center: { lat: 13.061931068989976, lng: 80.25831401348114 },

      mapOptions: {
        disableDefaultUI: false,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
    };
  },
  created() {
    this.getSchollDetails();
    this.getfacilitiy();
    this.schoolGallaryList();
  },
  computed: {
    styles(){
      var brwHeight = window.innerHeight;
      return{
        height: (brwHeight - 90) + 'px'
      };
    },
    getformatClassList() {
      if (this.Facilities.length > 0) {
        return this.Facilities.map((x) => {
          return {
            label: x.name,
            value: x._id,
          };
        });
      } else {
        return [];
      }
    },
  },

  mounted() {
    document.getElementById("map").innerHTML = document.getElementById(
      "map"
    ).innerHTML;
    this.$refs.address.focus();
  },

  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
    getAddressData(addressData, placeResultData, id) {
      (this.schoolDetails.address = addressData.route || ""),
        (this.schoolDetails.street = addressData.route || ""),
        (this.schoolDetails.state =
          addressData.administrative_area_level_1 || ""),
        (this.schoolDetails.district =
          addressData.administrative_area_level_2 || ""),
        (this.schoolDetails.pincode = addressData.postal_code || "");
      this.schoolDetails.city = addressData.locality || "";
      this.marker.position.lng = addressData.longitude || "";
      this.marker.position.lat = addressData.latitude || "";

      this.panToMarker();
      this.schoolDetails.longitude = addressData.longitude.toString() || "";
      this.schoolDetails.latitude = addressData.latitude.toString() || "";

      if (this.schoolDetails.state) {
        this.schoolDetails.state = this.stateList[this.schoolDetails.state];
      }
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    editDetails() {
      this.isEdit = true;
    },
    editCancel() {
      this.isEdit = false;
      this.getSchollDetails();
    },
    setToClassAndSection(classId) {
      if (classId) {
        const filterByToClass = this.fromClassList.filter((x) => x != classId);
        this.toClassList = filterByToClass;
      } else {
        this.toClassList = [];
        this.schoolDetails.gradeTo = null;
       
      }
    },
    
    async schoolGallaryList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viGetOnly(`/common/galleryList?accountId=${userData.userDetails.account._id}&branchId=${userData.userDetails.branch._id}`);

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
          this.galleryList = details.gallery || [];
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async checkAffilliateId() {
      const response = await ViService.viGetOnly(
        `/common/checkApiName?cName=Account&affilliateId=${this.schoolDetails.affilliateId}`
      );

      if (response.isSuccess) {
        this.isAffiliateIdAvailable = response.isApiNameAvailable;
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
    },
    openSchoolImage(path) {
      this.$bvModal.show("imagePopup");
    },
    closeImagePopUp() {
      this.$bvModal.hide("imagePopup");
    },

    async createAcademicYear() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("academicyr").then((result) => {
        isFormValid = result;
      });
      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push('/login');
        } else {
        //  this.viLoader = true;

          if (this.academicYear.from != null && this.academicYear.to != null) {
            this.academicYear.from = this.academicYear.from._i;
            this.academicYear.to = this.academicYear.to._i;
            const response = await ViService.viXPost(
              "/academicYear/createAcademicYear",
              this.academicYear,
              userData.token
            );

            if (response.isSuccess) {
              this.closePoup();

              if (this.$route.path != "/admission/academicYear") {
                this.$emit("callAcademicYearList");
              }

              this.getAcademicYearList();
              this.academicYear._id = null;
              this.academicYear.from = null;
              this.academicYear.to = null;
              this.$toasted.success(response.message);
            } else {
              this.academicYear._id = null;
              this.academicYear.from = null;
              this.academicYear.to = null;
              this.$toasted.error(response.message);
            }
          } else {
            this.$toasted.error("field should not be blank");
          }
          this.viLoader = false;
        }
      } else {
        this.$toasted.error(message);
      }
    },
    async updateFile(type, docType) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0];
        const fd = new FormData();
        fd.append(
          "doc",
          docFileDetails,
          docFileDetails.name,
          docFileDetails.actType
        );
        const response = await ViService.viPutOnly(
          `/document/uploadFile/branchGallery/${userData.userDetails.branch._id}/${type}`,
          fd,
          userData.token
        );

        // const response = await ViService.viPutOnly(
        //   `/document/uploadFile/${userData.userDetails.account._id}/${type}`,
        //   fd,
        //   userData.token
        // );
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getSchollDetails();
          this.$bvModal.hide("imagePopup");
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    openGalleryShowPopUp(index, item) {
      console.log("index", index);
      console.log("item", item);
    },
    async removeUploadFile(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          `/document/removeUploadFile/${userData.userDetails.account._id}/${type}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getSchollDetails();
          this.$bvModal.hide("imagePopup");
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },

    async getSchollDetails() {
      this.viLoader = true;

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        
        const response = await ViService.viGetOnly(`/common/branchWithAccountdetails?accountId=${userData.userDetails.account._id}&branchId=${userData.userDetails.branch._id}`);

        // const response = await ViService.viGetOnly(
        //   "/common/details?accountId=" + userData.userDetails.account._id
        // );

        if (response.isSuccess) {
          const schoolResult = secureUI.secureGet(response.data);
           let getDetails = this.$store.getters.individualSchoolInfoObject
           getDetails.accountName = schoolResult.accountName
          //this.$store.dispatch('setIndividualSchoolInfo', getDetails);


          this.schoolDetails = schoolResult;

          this.schoolDetails.currencyCode = this.schoolDetails.currencyCode


          this.schoolDetails.promoted = this.schoolDetails.promoted
            ? "Yes"
            : "No";
          if (this.schoolDetails.gradeFrom) {
            this.setToClassAndSection(this.schoolDetails.gradeFrom);
          }
          if (this.schoolDetails.YearOfSchoolEstablishment) {
            this.schoolDetails.YearOfSchoolEstablishment = this.schoolDetails.YearOfSchoolEstablishment.substring(
              0,
              10
            );
          }
          if (this.schoolDetails.schoolAffiliationGranted) {
            this.schoolDetails.schoolAffiliationGranted = this.schoolDetails.schoolAffiliationGranted.substring(
              0,
              10
            );
          }

          this.marker.position.lat = Number(this.schoolDetails.latitude);
          this.marker.position.lng = Number(this.schoolDetails.longitude);

          this.schoolDetails.isVerified = this.schoolDetails.isVerified
            ? this.schoolDetails.isVerified
            : false;
          if (this.schoolDetails.facilities.length > 0) {
            this.schoolDetails.facilities = this.schoolDetails.facilities.map(
              (x) => x._id
            );
          }
          console.log("schoolResultschoolResult",schoolResult)
          this.$toasted.success(response.message);
          const myTimeout = setTimeout(this.geolocate, 1000);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },
    async getfacilitiy() {
     // let userData = secureUI.sessionGet("user");

      // if (!userData) {
      //   this.$toasted.error("Please login and do the action");
      // } else {
       
       const response = await ViService.viGetOnly("/app/getFacilitiyList");

        if (response.isSuccess) {
          this.Facilities = secureUI.secureGet(response.data);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
     // }
    },
    async updateDetails() {
      
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("schOverview").then((result) => {
        isFormValid = result;
      });
      if (isFormValid && this.isAffiliateIdAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.schoolDetails.latitude = this.marker.position.lat;
          this.schoolDetails.longitude = this.marker.position.lng;
            if(this.schoolDetails.status == 'Register' || this.schoolDetails.status == 'Registered'){
              this.schoolDetails.status = 'Submitted'
            }
          const response = await ViService.viXPut(
            `/account`,
            this.schoolDetails,
            userData.token
          );

          if (response.isSuccess) {
            this.getSchollDetails();
            this.$toasted.success(response.message);
            
            if (userData.userDetails.account.version == "paidVersion") {
              this.$router.push("/admission/home");
            } else {
              this.$router.push("/enrollment/school");
            }
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      } else {
        this.$toasted.error(message);
      }
    },
    async removepic(index) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const remaining = [...this.galleryList];
        remaining.splice(index, 1);
        const response = await ViService.viXPut(
          `/document/removeGalleryFile/${userData.userDetails.account._id}`,
          remaining,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.galleryList.splice(index, 1);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },

    async updateGalleryFile(type, docType) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0];
        const fd = new FormData();
        fd.append(
          "doc",
          docFileDetails,
          docFileDetails.name,
          docFileDetails.actType
        );

        const response = await ViService.viPutOnly(
          `/document/uploadFile/branchGallery/${userData.userDetails.branch._id}/${type}`,
          fd,
          userData.token
        );
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.schoolGallaryList();
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      document.getElementById("importExportID").value=null; 
    },

    geolocate() {
      this.panToMarker();
    },

    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },

    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
  },
};
</script>
